import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {
  Box,
  Button,
  Grid,
  Link,
  Slide,
  useScrollTrigger,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import BasicMenu from "./basicmenu";

interface Props {
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function ButtonAppBar() {
  const navigate = useNavigate();

  const style = {
    height: "10vh",
    backgroundColor: "rgba(2,3,4, 0.5)",
    width: "100%",
    top: "0",
  };

  return (
    <HideOnScroll>
      <AppBar position="absolute" style={style}>
        <Toolbar>
          <Grid
            container
            direction={"row"}
            justifyContent={"space-evenly"}
            alignItems={"space-evenly"}
          >
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <BasicMenu />
            </Box>

            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Button
                onClick={() => navigate("/members")}
                sx={{
                  fontFamily: "BlinkMacSystemFont",
                  color: "rgba(242,243,244, 0.85)",
                  fontWeight: 600,
                  fontSize: "20px",
                }}
              >
                Medlem
              </Button>
            </Box>

            <Box sx={{ display: "block" }}>
              <Link
                href="/"
                underline="none"
                variant="h4"
                sx={{
                  fontSize: { xs: "20px", md: "36px" },
                  fontFamily: "BlinkMacSystemFont",
                  flexGrow: 1,
                  color: "rgba(242,243,244, 0.85)",
                }}
                fontWeight={800}
              >
                Umeå Curlingklubb
              </Link>
            </Box>

            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Button
                onClick={() => navigate("/contactus")}
                sx={{
                  fontFamily: "BlinkMacSystemFont",
                  color: "rgba(242,243,244, 0.85)",
                  fontWeight: 600,
                  fontSize: "20px",
                  display: { md: "" },
                }}
              >
                Kontakta oss
              </Button>
            </Box>
          </Grid>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}
