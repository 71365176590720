import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { height } from "@mui/system";
import { Stack } from "@mui/material";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {"Copyright © "}
      <Link color="inherit" href="https://umecurling.se/">
        Umeå Curlingklubb
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function StickyFooter() {
  const style = {
    height: "15vh",
    backgroundColor: "rgba(242,243,244, 0.30)",
    width: "100%",
    position: "relative",
    bottom: "0",
  };

  return (
    <Box sx={{}}>
      <Stack justifyContent={"center"} direction={"column"} sx={style}>
        <Typography variant="body1">
          POSTADRESS: Umeå CK - Curling Signalvägen 11 90322 Umeå
        </Typography>

        <Typography variant="body1">
          KONTAKT: Tel: 0705402884 E-post: info@umecurling.se
        </Typography>
        <Copyright />
      </Stack>
    </Box>
  );
}
