import React from "react";
import "./App.css";
import SimpleContainer from "./pages/home/body/container";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ContactUs from "./pages/contactus";
import ButtonAppBar from "./header/header";
import HomePage from "./pages/home/home";
import StickyFooter from "./footer/footer";
import { Container } from "@mui/material";
import BookingInfo from "./pages/booking/bookinginfo";
import MembersHomePage from "./pages/members/membershome";
import LandingPage from "./pages/landing/landing";
import Header from "./header/header";

function App() {
  return (
    <BrowserRouter>
      <header style={{ width: "100%" }}>
        <ButtonAppBar />
      </header>
      <body className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/bookinginfo" element={<BookingInfo />} />
          <Route path="/members" element={<MembersHomePage />} />
        </Routes>
        <StickyFooter></StickyFooter>
      </body>
    </BrowserRouter>
  );
}

export default App;
