import { Box, Container, Grid } from "@mui/material";
import React from "react";
import Offer, { OfferProps } from "./offer";

export default function Offers() {
  const offers: OfferProps[] = [
    {
      img: "/business.jpeg",
      title: "FÖRETAG",
      description: `Vi erbjuder företag att komma och spela curling för 2000kr per
      bana för 1:45h med instruktör med ett maximalt antal av 10
      personer per bana.`,
      end: "flex-start",
    },
    {
      img: "/private.jpeg",
      title: "PRIVAT",
      description: `Vi erbjuder privatpersoner att komma och spela curling för 200kr per person med ett minimumpris av 800kr för 1:45h med instruktör.`,
      end: "flex-end",
    },
    {
      img: "/students.jpeg",
      title: "STUDENTS",
      description: `We offer students to come and play curling for 100 SEK per person with a total minimum amount of 800 SEK. This will include an instructor. The duration is 1:45h.`,
      end: "flex-start",
    },
    {
      img: "/junior.jpeg",
      title: "JUNIOR",
      description: `Vi erbjuder juniorer (upp till 18 år) att komma och spela curling för 100kr per person med ett minimumpris av 800kr för 1:45h med instruktör. Vi erbjuder även juniorträning måndagar klockan 17.45 om man vill börja spela.`,
      end: "flex-end",
    },
    {
      img: "/wheelchair.jpeg",
      title: "RULLSTOL",
      description: `Vi erbjuder rullstolsburna att komma och spela curling för 100kr per person i 1:45h med instruktör.`,
      end: "flex-start",
    },
    {
      img: "/skola_1.jpeg",
      title: "SKOLA",
      description: `Vi erbjuder skolor att komma och spela curling för 1000kr per 3 banor från måndag till fredag med ett maximalt antal av 30 elever.`,
      end: "flex-end",
    },
  ];

  return (
    <Box>
      {offers.map((offer) => (
        <Offer {...offer} key={offer.title} />
      ))}
    </Box>
  );
}
